import React, { useRef, useState } from "react";
// react plugin that prints a given react component
import ReactToPrint, { useReactToPrint } from "react-to-print";
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import { Button, Card, CardBody, Col, Container, Modal, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import InspectionViewComp from "../../../components/Inspection/InspectionViewComp";
import {
  employeeRoutes,
  managerRoutes,
} from "../../../endpoints/base.endpoint";
import * as managerEndpoints from "../../../endpoints/manager.endpoint";
import * as employeeEndpoints from "../../../endpoints/employee.endpoint";

function InspectionView() {
  const [alert, setAlert] = useState(null);
  const [actionModal, toggleModal] = useState(false);
  const access_level = sessionStorage.getItem("access_level");
  const [approveInsp, setapproveInsp] = useState();
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  if (access_level === "1") {
    Routes = managerRoutes;
    endpoints = managerEndpoints;
  } else if (access_level === "2") {
    Routes = employeeRoutes;
    endpoints = employeeEndpoints;
  }

  const [
    {
      data: getInspectionData,
      loading: InspectionDataLoading,
      error: InspectionDataError,
    },
    refreshInspectionData,
  ] = Routes(
    {
      url: endpoints.view_inspection.path,
      method: endpoints.view_inspection.method,
      headers: config.header,
      data: {
        iid: sessionStorage.getItem("inspection_manage_id"),
      },
    },
    { manual: false }
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({});

  const [{ apInsp, apInspLoading, apInspError }, sendApproveInsp] =
    managerRoutes(
      {
        url: managerEndpoints.approve_inspection.path,
        method: managerEndpoints.approve_inspection.method,
        headers: config.header,
        data: {
          iid: sessionStorage.getItem("inspection_manage_id"),
          status: approveInsp,
        },
      },
      { manual: true }
    );

  function handleApproveInsp() {
    sendApproveInsp().then((res) => {
      window.location.href = "./inspection-list";
    });
  }
  return (
    <>
      {alert}
      <SimpleHeader name="Inspection Manage" parentName="Inspection" />
      {access_level === "1" ? (
        <Modal
          className="modal-dialog-centered"
          isOpen={actionModal}
          toggle={() => toggleModal(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Inspection Action
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {" "}
            <div className="custom-control custom-checkbox custom-checkbox-success">
              <h5>Inspection Status</h5>
              <input
                className="custom-control-input"
                id="customCheck1"
                value={"Approved"}
                onChange={(e) => {
                  setapproveInsp(e.target.value);
                }}
                type="checkbox"
              />
              <label className="custom-control-label " htmlFor="customCheck1">
                Approved
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal(false)}
            >
              Close
            </Button>
            <Button color="default" type="button" onClick={handleApproveInsp}>
              Save changes
            </Button>
          </div>
        </Modal>
      ) : null}
      <Container className={"mt--6"} fluid>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <a href="./inspection-list" className="text-white">
                  <Button size="sm" color="default">
                    Back
                  </Button>
                </a>
              </Col>
              <Col>
                <div>
                  <ReactToPrint
                    copyStyles={true}
                    documentTitle="Inspection.pdf"
                    trigger={() => (
                      <Button
                        color="primary"
                        size="sm"
                        className="buttons-copy buttons-html5"
                        id="print-tooltip"
                      >
                        Print
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </Col>
              <Col>
                <div>
                  {access_level === "1" ? (
                    <Button
                      color="default"
                      size="sm"
                      onClick={() => toggleModal(true)}
                    >
                      Action
                    </Button>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div>Status : {getInspectionData?.inspection?.status}</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div ref={componentRef} className={"pt-3"}>
                  <InspectionViewComp props={getInspectionData} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      {/* <div ref={componentRef} className={"pt-5"}>
        <InspectionViewComp props={getInspectionData} />
      </div> */}
    </>
  );
}

export default InspectionView;
