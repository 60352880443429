import React, { useEffect, useRef, useState } from "react";
// react plugin that prints a given react component
import ReactToPrint, { useReactToPrint } from "react-to-print";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import {
  employeeRoutes,
  managerRoutes,
} from "../../../endpoints/base.endpoint";
import * as managerEndpoints from "../../../endpoints/manager.endpoint";
import * as employeeEndpoints from "../../../endpoints/employee.endpoint";

// work order component
import WorkOrderComp from "../../../components/WorkOrder/WorkOrderComp";
import Select from "react-select";
import { useHistory } from "react-router-dom";

function WorkOrderView() {
  const [alert, setAlert] = React.useState(null);
  const [WorkOrderActionModal, setWorkOrderActionModal] = useState(false);
  const [toggleAssignModal, setToggleAssignModal] = useState(false);
  const [WorkOrderActionObject, setWorkOrderActionObject] = useState();
  const [assignEngineer, setAssignEngineer] = useState();
  const [engineerWOStatus, setEngineerWOStatus] = useState("Ongoing");
  const history = useHistory();

  const access_level = sessionStorage.getItem("access_level");
  const loggedInUsername = sessionStorage.getItem("name");
  const loggedInUserId = sessionStorage.getItem("user_id");
  let actionWorkOrderValue = {
    wo_id: sessionStorage.getItem("work_order_manage_id"),
  };
  let Routes;
  let endpoints;
  let modal;
  function closeModal() {
    if (WorkOrderActionModal) {
      setWorkOrderActionModal(false);
    } else {
      setWorkOrderActionModal(true);
    }
  }

  const toggleAssignWorkOrderModal = () => {
    setToggleAssignModal(!toggleAssignModal);
  };

  useEffect(() => {
    console.log(WorkOrderActionObject);
  }, [WorkOrderActionObject]);

  const handleWO = (typeofObj, value) => {
    actionWorkOrderValue[typeofObj] = value;

    // initialize empty comment object
    let comment = {};
    // add value to comment object
    // based on login type
    if (access_level === "1") {
      comment.managerName = loggedInUsername;
      comment.managerId = loggedInUserId;
      comment.time = new Date();
      if (typeofObj === "comment") {
        comment.comment = value;
      }
    } else if (access_level === "2") {
      comment.engineerName = loggedInUsername;
      comment.engineerId = loggedInUserId;
      comment.time = new Date();
      if (typeofObj === "comment") {
        comment.comment = value;
      }
    }

    console.log(comment);
    setWorkOrderActionObject((WorkOrderActionObject) => ({
      ...WorkOrderActionObject,
      ...actionWorkOrderValue,
      comment: comment,
    }));
    console.log(WorkOrderActionObject);
  };
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };
  console.log(config);
  if (access_level === "1") {
    Routes = managerRoutes;
    endpoints = managerEndpoints;
  } else if (access_level === "2") {
    Routes = employeeRoutes;
    endpoints = employeeEndpoints;
  }

  const [
    { data: getWODetail, loading: WODetailLoading, error: WODetailError },
    refreshWODetail,
  ] = Routes(
    {
      url: endpoints.view_work_order.path,
      method: endpoints.view_work_order.method,
      headers: config.header,
      data: {
        wo_id: sessionStorage.getItem("work_order_manage_id"),
      },
    },
    { manual: false }
  );

  const [
    { data: updateWOStatus, loading: WOStatusLoading, error: WOStatusError },
    sendWOStatus,
  ] = Routes(
    {
      url: endpoints.update_status_work_order.path,
      method: endpoints.update_status_work_order.method,
      headers: config.header,
    },
    { manual: true }
  );

  const [
    { loading: engineerAssignLoading, error: engineerAssignError },
    assignEngineerAPI,
  ] = managerRoutes(
    {
      url: managerEndpoints.assign_engineer.path,
      method: managerEndpoints.assign_engineer.method,
      headers: config.header,
      data: {
        wo_id: actionWorkOrderValue.wo_id,
        engineerId: assignEngineer?._id,
        engineerName: assignEngineer?.name,
      },
    },
    { manual: true }
  );

  const [
    { data: getEmployee, loading: getEmployeeLoading, error: getEmployeeError },
    fetchAllEmployee,
  ] = managerRoutes(
    {
      url: managerEndpoints.list_employees.path,
      method: managerEndpoints.list_employees.method,
      headers: config.header,
    },
    { manual: false }
  );

  let EmployeeList = [];

  if (getEmployee) {
    getEmployee.map((emp) =>
      EmployeeList.push({ value: emp, label: emp.name })
    );
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({});

  const managerWOActionModal = (
    <Modal
      className="modal-dialog-centered h-100"
      backdrop={true}
      size="lg"
      centered={true}
      scrollable={true}
      isOpen={WorkOrderActionModal}
      toggle={closeModal}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Work Order Mark Approved
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Work Order Status Update
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="10">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-work-details"
                  >
                    Manager Comments
                  </label>
                  <Input
                    id="input-work-details"
                    rows="6"
                    type={"textarea"}
                    placeholder={"Manager Comments"}
                    onChange={(e) => {
                      handleWO("comment", e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={10} lg={8}>
                <FormGroup>
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="markComplete"
                      type="checkbox"
                      value={"Approved"}
                      onChange={(e) => {
                        handleWO("status", e.target.value);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="markComplete"
                    >
                      Approved / Mark Finish / Closed
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          color="default"
          onClick={() => {
            handleWorkOrderActionSubmit();
          }}
        >
          Update Work Order
        </Button>{" "}
        <Button onClick={closeModal}>Cancel</Button>
      </div>
    </Modal>
  );

  const employeeWOActionModal = (
    <Modal
      className="modal-dialog-centered h-100"
      backdrop={true}
      size="lg"
      centered={true}
      scrollable={true}
      isOpen={WorkOrderActionModal}
      toggle={closeModal}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Work Order Mark Complete
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Work Order Status Update
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-work-details"
                  >
                    Employee Comments
                  </label>
                  <Input
                    id="input-work-details"
                    rows="6"
                    type={"textarea"}
                    placeholder={"Employee Comments"}
                    onChange={(e) => {
                      handleWO("comment", e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={12} md={12} sm={12}>
                <FormGroup>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="status_ongoing"
                      name="status"
                      type="radio"
                      value={"Ongoing"}
                      defaultChecked
                      onChange={(e) => {
                        setEngineerWOStatus(e.target.value);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="status_ongoing"
                    >
                      Mark As Ongoing
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="status_complete"
                      name="status"
                      type="radio"
                      value={"Complete"}
                      onChange={(e) => {
                        setEngineerWOStatus(e.target.value);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="status_complete"
                    >
                      Mark Completed
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button color="default" onClick={handleWorkOrderActionSubmit}>
          Update Work Order
        </Button>{" "}
        <Button onClick={closeModal}>Cancel</Button>
      </div>
    </Modal>
  );
  const managerTookActionModal = (
    <Modal
      className="modal-dialog-centered h-100"
      backdrop={true}
      size="lg"
      centered={true}
      scrollable={true}
      isOpen={WorkOrderActionModal}
      toggle={closeModal}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Work Order Mark Complete
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Work Order Status Update
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <h4>
                  Manager Already Commented And Work Order Marked Approved
                </h4>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button onClick={closeModal}>Cancel</Button>
      </div>
    </Modal>
  );

  const empTookActionModal = (
    <Modal
      className="modal-dialog-centered h-100"
      backdrop={true}
      size="lg"
      centered={true}
      scrollable={true}
      isOpen={WorkOrderActionModal}
      toggle={closeModal}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Work Order Mark Complete
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Work Order Status Update
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <h4>
                  Employee Already Commented And Work Order Marked Complete
                </h4>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button onClick={closeModal}>Cancel</Button>
      </div>
    </Modal>
  );
  const empActionPending = (
    <Modal
      className="modal-dialog-centered h-100"
      backdrop={true}
      size="lg"
      centered={true}
      scrollable={true}
      isOpen={WorkOrderActionModal}
      toggle={closeModal}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Work Order Mark Complete
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Work Order Status Update
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <h4>
                  Status is still Active , Employee Action Pending for this
                </h4>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button onClick={closeModal}>Cancel</Button>
      </div>
    </Modal>
  );
  const assignEngineerModal = (
    <Modal
      className="modal-dialog-centered"
      backdrop={true}
      size="lg"
      centered={true}
      scrollable={true}
      isOpen={toggleAssignModal}
      toggle={toggleAssignWorkOrderModal}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Assign Engineer
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleAssignWorkOrderModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <h6 className="heading-small text-muted mb-4">Engineer to assign</h6>
          <div className="pl-lg-4" style={{ minHeight: "250px" }}>
            <Row>
              <Col lg="12">
                <Select
                  options={EmployeeList}
                  id="AssignEngineer"
                  onChange={(e) => {
                    setAssignEngineer(e.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          type="button"
          color="default"
          onClick={() => {
            handleAssignEngineerSubmit();
          }}
        >
          Assign
        </Button>
        <Button onClick={toggleAssignWorkOrderModal}>Cancel</Button>
      </div>
    </Modal>
  );

  const empActionOngoing = (
    <Modal
      className="modal-dialog-centered h-100"
      backdrop={true}
      size="lg"
      centered={true}
      scrollable={true}
      isOpen={WorkOrderActionModal}
      toggle={closeModal}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Work Order Mark Complete
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Work Order Status Update
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <h4>Status is still Ongoing , Check Engineer Comment</h4>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button onClick={closeModal}>Cancel</Button>
      </div>
    </Modal>
  );

  if (getWODetail !== undefined) {
    if (access_level === "1") {
      modal = managerWOActionModal;
      if (getWODetail.workorder.status === "Approved") {
        modal = managerTookActionModal;
      }
      if (getWODetail.workorder.status === "Open") {
        modal = empActionPending;
      }
      if (getWODetail.workorder.status === "Ongoing") {
        modal = empActionPending;
      }
    } else if (access_level === "2") {
      modal = employeeWOActionModal;
      if (getWODetail.workorder.status === "Complete") {
        modal = empTookActionModal;
      }
      if (getWODetail.workorder.status === "Approved") {
        modal = managerTookActionModal;
      }
    }
  }
  function handleWorkOrderActionSubmit() {
    console.log("handleWorkOrderActionSubmit", WorkOrderActionObject);

    if (access_level === "2") {
      sendWOStatus({
        data: { ...WorkOrderActionObject, status: engineerWOStatus },
      }).then((data) => {
        if (data.status === 200) {
          window.location.href = "./work-order-list";
        }
      });
    } else {
      sendWOStatus({ data: WorkOrderActionObject }).then((data) => {
        if (data.status === 200) {
          window.location.href = "./work-order-list";
        }
      });
    }
  }

  function handleAssignEngineerSubmit() {
    assignEngineerAPI().then((data) => {
      console.log("assigned data", data);
      if (data.status === 200) {
        setToggleAssignModal(!toggleAssignModal);
      }
    });
  }

  return (
    <>
      {alert}
      {modal}
      {assignEngineerModal}
      <SimpleHeader name="Work Order Details" parentName="Work Order" />
      <Container className={"mt--6"} fluid>
        <Card>
          <CardBody>
            <Row>
              <Col sm={4}>
                <Button
                  size="sm"
                  color="default"
                  className="mx-2"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col sm={4}>
                <ReactToPrint
                  copyStyles={true}
                  documentTitle="Work Order"
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </Col>
              <Col sm={4}>
                <div className="">
                  {access_level === "1" ? (
                    <>
                      <Button
                        color="default"
                        size="sm"
                        className="buttons-copy buttons-html5"
                        onClick={(e) => {
                          closeModal();
                        }}
                      >
                        Mark Approved / Closed
                      </Button>
                      {getWODetail &&
                        !getWODetail.workorder.engineerAssigned && (
                          <Button
                            size="sm"
                            color="info"
                            onClick={toggleAssignWorkOrderModal}
                          >
                            Assign
                          </Button>
                        )}
                    </>
                  ) : null}

                  {access_level === "2" ? (
                    <Button
                      type={"button"}
                      onClick={(e) => {
                        closeModal();
                      }}
                    >
                      Mark Complete
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div ref={componentRef} className={"pt-3"}>
                  {getWODetail && <WorkOrderComp props={getWODetail} />}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      {/* <div ref={componentRef} className={"pt-5"}>
        {getWODetail && <WorkOrderComp props={getWODetail} />}
      </div> */}
    </>
  );
}

export default WorkOrderView;
