import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import moment from "moment/moment";
import petrology_solutions from "../../assets/img/petrology_solutions.png";

function WorkOrderComp(props, ref) {
  const workOrder = props.props.workorder;
  const station = props.props.station;

  const stationName = workOrder.stationName ? workOrder.stationName : "";
  const date = workOrder.date ? workOrder.date : "";
  const stationId = station.stationId ? station.stationId : "";
  const issue = workOrder.issue ? workOrder.issue : "";
  const status = workOrder.status ? workOrder.status : "";
  const workDetails = workOrder.workDetails ? workOrder.workDetails : "";
  const comment = workOrder.comment ? workOrder.comment : "";
  const stationAddress = station.stationAddress ? station.stationAddress : "";
  const stationCity = station.city ? station.city : "";
  const stationState = station.state ? station.state : "";
  const facilityId = station.facilityId ? station.facilityId : "";
  const ownerId = station.ownerId ? station.ownerId : "";
  const contact = station.stationContact ? station.stationContact : "";
  const stationZip = station.zip ? station.zip : "";
  const stationPhone = station.phone ? station.phone : "";
  const engineerName = workOrder.engineerName
    ? workOrder.engineerName
    : "Engineer not assigned";
  const engineerContact = workOrder.engineerContact
    ? workOrder.engineerContact
    : "";
  const managerComment = workOrder.managerComment
    ? workOrder.managerComment
    : "";
  const employeeComment = workOrder.employeeComment
    ? workOrder.employeeComment
    : "";
  return (
    <>
      {/* <div className="mt--6"> */}
      {/* <Card> */}
      {/* <CardBody> */}
      {/* <h3 className="mb-0 d-print-none">Work Order</h3> */}
      <Row className={"pt-5 d-print-flex d-none"}>
        <Col sm="5" className={" d-inline"}>
          <img
            src={petrology_solutions}
            className={" d-inline-flex"}
            width={"250px"}
            alt={"Petrology"}
          />
        </Col>
        <Col sm="5" className={"text-right d-inline text-top"}>
          <h4>
            11501 Windsor Ln., # 102,
            <br />
            Houston, TX 77031.
            <br />
            Tel#: 832-500-7867 (PUMP)
            <br />
            Fax #. 1-877-277-9031
          </h4>
        </Col>
        <Col sm="12">
          <h2 className={"d-print-block d-none "}>Work Order</h2>
        </Col>
      </Row>

      <h6 className="heading-small text-muted">Station information</h6>
      <Row className="pt-2">
        <Col xs={"4"}>
          <h4>
            <span>Site Name : {stationName}</span>
            <br />
            <span>Site Address : {stationAddress}</span>
            <br />
            <span>City : {stationAddress}</span>
            <br />
          </h4>
        </Col>
        <Col xs={"4"}>
          <h5>
            <span>Facility ID # : {facilityId}</span>
            <br />
            <span>Owner ID # : {ownerId}</span>
            <br />
            <span>State : {stationCity}</span>
            <br />
          </h5>
        </Col>
        <Col xs={"4"}>
          <h5>
            <span>Site Contact : {contact}</span>
            <br />
            <span>Site Phone # : {stationPhone} </span>
            <br />
            <span>Zip : {stationZip}</span>
            <br />
          </h5>
        </Col>
      </Row>

      <hr className="my-4" />

      <h6 className="heading-small text-muted mb-4">Engineer information</h6>
      <Row>
        <Col lg={4} sm={6}>
          <FormGroup>
            <label className="form-control-label" htmlFor={"engName"}>
              Engineer Name
            </label>
            <Input
              type={"text"}
              name={"Engineer Name"}
              id={"engName"}
              value={engineerName}
              placeholder={"Test Eng"}
              disabled={true}
            />
          </FormGroup>
        </Col>
        {/*<Col lg={6} sm={6}>
              <FormGroup>
                <label className="form-control-label" htmlFor={"engName"}>
                  Engineer Contact
                </label>
                <Input
                  type={"text"}
                  name={"Engineer Contact"}
                  id={"engName"}
                  value={engineerContact}
                  placeholder={"Engineer Contact"}
                  disabled={true}
                />
              </FormGroup>
            </Col>*/}
      </Row>

      <Row>
        <Col lg={4} sm={6}>
          <FormGroup>
            <label className="form-control-label" htmlFor={"date"}>
              Work Order Created At
            </label>
            <Input
              type={"date"}
              name={"search"}
              id={"date"}
              value={moment(date).format("YYYY-MM-DD")}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col lg={6} sm={6}>
          {/*<FormGroup>
                <label className="form-control-label" htmlFor={"engName"}>
                  Work Order Submitted / Completed At
                </label>
                <Input
                  type={"date"}
                  name={"Engineer Name"}
                  id={"engName"}
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  disabled={true}
                />
              </FormGroup>*/}
        </Col>
      </Row>

      <hr className="my-4" />

      <h6 className="heading-small text-muted mb-4">Work Order information</h6>
      <Row>
        <Col lg={8} sm={10}>
          <FormGroup>
            <label className="form-control-label" htmlFor={"stationName"}>
              Work Order Details
            </label>
            <Input
              type={"textarea"}
              id="input-work-details"
              className={"p-1"}
              rows={10}
              name={"workDetails"}
              value={workDetails}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col lg={8} sm={10}>
          <FormGroup>
            <label className="form-control-label" htmlFor={"status"}>
              Status:
            </label>
            <Input
              type={"text"}
              id={"status"}
              value={status}
              disabled
              className={"font-weight-bold"}
            />
          </FormGroup>
        </Col>
        {/*<Col lg={10} sm={10}>
              <FormGroup>
                <label className="form-control-label" htmlFor={"comment"}>
                  Comments
                </label>
                <Input
                  type={"textarea"}
                  name={"comment"}
                  id={"comment"}
                  rows={5}
                  value={comment}
                  className={"p-1"}
                  placeholder={"Comments Here"}
                  disabled={true}
                />
              </FormGroup>
            </Col>*/}

        {employeeComment && (
          <Col lg={8} sm={10}>
            <FormGroup>
              <label className="form-control-label" htmlFor={"comment"}>
                Engineer Comments
              </label>
              <Input
                type={"textarea"}
                name={"employeeComment"}
                id={"employeeComment"}
                rows={6}
                value={employeeComment}
                className={"p-1"}
                placeholder={"Employee Comments "}
                disabled={true}
              />
            </FormGroup>
          </Col>
        )}
        {managerComment && (
          <Col lg={8} sm={10}>
            <FormGroup>
              <label className="form-control-label" htmlFor={"comment"}>
                Manager Comments
              </label>
              <Input
                type={"textarea"}
                name={"managerComment"}
                id={"managerComment"}
                rows={6}
                value={managerComment}
                className={"p-1"}
                placeholder={"Manager Comments"}
                disabled={true}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      {/* </CardBody> */}
      {/* </Card> */}
      {/* </div> */}
    </>
  );
}

export default WorkOrderComp;
