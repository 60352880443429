import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from "moment";

// react plugin used to create datetimepicker
// reactstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  // CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  employeeRoutes,
  managerRoutes,
} from "../../../endpoints/base.endpoint";
import * as managerEndpoints from "../../../endpoints/manager.endpoint";
import * as employeeEndpoints from "../../../endpoints/employee.endpoint";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
            defaultValue={"25"}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function PendingWorkOrderList() {
  const [alert, setAlert] = React.useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const componentRef = React.useRef(null);
  const access_level = sessionStorage.getItem("access_level");

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  function StationStatusFormatter(cell, row) {
    if (row.status === "Pending") {
      return (
        <Badge className="badge-md" color="danger" pill>
          <strong>Pending</strong>
        </Badge>
      );
    }
  }

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let modal;
  let Routes;
  let endpoints;

  if (access_level === "1") {
    Routes = managerRoutes;
    endpoints = managerEndpoints;
  } else if (access_level === "2") {
    Routes = employeeRoutes;
    endpoints = employeeEndpoints;
  }

  const [
    {
      data: getWorkOrder,
      loading: getWorkOrderLoading,
      error: getWorkOrderError,
    },
    refreshgetWorkOrder,
  ] = Routes(
    {
      url: endpoints.list_work_order.path,
      method: endpoints.list_work_order.method,
      headers: config.header,
    },
    { manual: false }
  );

  let filteredWorkOrders = [];

  if (getWorkOrder) {
    console.log(getWorkOrder);

    // new work order on top based on date
    getWorkOrder.sort(
      (currentWorkOrder, nextWorkOrder) =>
        new Date(nextWorkOrder.date) - new Date(currentWorkOrder.date)
    );

    // Filter the array first with status = 'Pending'
    filteredWorkOrders = getWorkOrder.filter(
      (data) => data.status === "Pending"
    );

    // Map over the filtered array and modify the button
    filteredWorkOrders.forEach((data, idx) => {
      data.btn = (
        <a
          href={"./work-order-detail"}
          className="btn btn-success btn-sm d-print-none"
          onClick={(e) =>
            sessionStorage.setItem("work_order_manage_id", data._id)
          }
        >
          Details
        </a>
      );
    }); //to modify the button in the table
  }
  function dateFormatter(cell, row) {
    return moment(new Date(cell)).format("MM-DD-YYYY");
  }

  // on station name hover display station address in tooltip
  function displayAddress(cell, row, rowIndex) {
    return (
      <span
        data-toggle="tooltip"
        data-placement="top"
        title={row.stationAddress}
      >
        {row.stationName}
      </span>
    );
  }

  function displayWorkDetails(cell, row, rowIndex) {
    return (
      <span data-toggle="tooltip" data-placement="top" title={cell}>
        {cell}
      </span>
    );
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Pending Work Order List" parentName="Work Order" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              {/* <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Work Order List</h3>
                  </Col>
                </Row>
              </CardHeader> */}

              {getWorkOrder && (
                <ToolkitProvider
                  data={filteredWorkOrders}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "stationId",
                      hidden: true,
                    },
                    {
                      dataField: "stationName",
                      text: "Name",
                      sort: true,
                      formatter: displayAddress,
                    },
                    {
                      dataField: "date",
                      text: "Date",
                      sort: true,
                      formatter: dateFormatter,
                    },
                    // {
                    //   dataField: "stationAddress",
                    //   text: "Address",
                    //   sort: true,
                    // },
                    {
                      dataField: "issue",
                      text: "Origin",
                      sort: true,
                    },
                    {
                      dataField: "workDetails",
                      text: "Work Details",
                      sort: true,
                      formatter: displayWorkDetails,
                      style: {
                        maxWidth: "24rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    },
                    {
                      dataField: "engineerName",
                      text: "Engineer Assigned",
                      sort: true,
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: false,
                      formatter: StationStatusFormatter,
                      headerStyle: { textAlign: "center" },
                      style: {
                        textAlign: "center",
                      },
                    },
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                      headerStyle: { textAlign: "center" },
                      style: {
                        textAlign: "center",
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={9}>
                            <ButtonGroup className="float-left">
                              <Button
                                className="buttons-copy buttons-html5"
                                color="primary"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>

                          <Col
                            // className="text-right" xs="4"
                            xs={12}
                            sm={3}
                          >
                            {access_level === "1" && (
                              <Button
                                href={"./create-work-order"}
                                color="default"
                                size="sm"
                                className="float-right p-2"
                              >
                                New Work Order
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PendingWorkOrderList;
