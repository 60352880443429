import StationList from "../views/pages/station/StationList";
import WorkOrderList from "../views/pages/workOrder/WorkOrderList";
import InspectionList from "../views/pages/inspection/InspectionList";
import InspectionCreate from "../views/pages/inspection/InspectionCreate";
import WorkOrderView from "../views/pages/workOrder/WorkOrderView";
import InspectionView from "../views/pages/inspection/InspectionView";
import Test from "../views/pages/inspection/test";
import StationDetail from "../views/pages/station/StationDetail";
import PendingWorkOrderList from "views/pages/workOrder/PendingWorkOrderList";
import InspectionEdit from "../views/pages/inspection/InspectionEdit";

const employee_routes = [
  {
    collapse: true,
    name: "Station",
    icon: "ni ni-shop text-primary",
    state: "stationCollapse",
    access_level: "2",
    views: [
      {
        path: "/employee/station-list",
        name: "Station List",
        miniName: "SL",
        component: StationList,
        layout: "/admin",
        access_level: "2",
        invisible: false,
      },
      {
        path: "/employee/station-details",
        name: "Station Details",
        miniName: "SL",
        component: StationDetail,
        layout: "/admin",
        access_level: "2",
        invisible: true,
      },
    ],
  },

  {
    collapse: true,
    name: "Work Order",
    icon: "ni ni-shop  text-primary",
    state: "WOCollapse",
    access_level: "2",
    views: [
      {
        path: "/employee/work-order-list",
        name: "Work Order List",
        miniName: "WOL",
        component: WorkOrderList,
        layout: "/admin",
        access_level: "2",
        invisible: false,
      },
      {
        path: "/employee/pending-work-order-list",
        name: "Pending Work Order List",
        miniName: "PWOL",
        component: PendingWorkOrderList,
        layout: "/admin",
        access_level: "2",
        invisible: false,
      },
      {
        path: "/employee/work-order-detail",
        name: "Work Order Details",
        miniName: "WOL",
        component: WorkOrderView,
        layout: "/admin",
        access_level: "2",
        invisible: true,
      },
    ],
  },

  {
    collapse: true,
    name: "Inspection",
    icon: "ni ni-shop  text-primary",
    state: "InspectionCollapse",
    access_level: "2",
    views: [
      {
        path: "/employee/inspection-list",
        name: "Inspection List",
        miniName: "IL",
        component: InspectionList,
        layout: "/admin",
        access_level: "2",
        invisible: false,
      },
      {
        path: "/employee/create-inspection",
        name: "Create Inspection",
        miniName: "CI",
        component: InspectionCreate,
        layout: "/admin",
        access_level: "2",
        invisible: false,
      },
      {
        path: "/employee/inspection-detail",
        name: "Inspection Details",
        miniName: "IL",
        component: InspectionView,
        layout: "/admin",
        access_level: "2",
        invisible: true,
      },

      {
        path: "/employee/inspection",
        name: "Inspection Details",
        miniName: "IL",
        component: Test,
        layout: "/admin",
        access_level: "2",
        invisible: true,
      },
      {
        path: "/employee/inspection-edit",
        name: "Inspection Edit",
        miniName: "IE",
        component: InspectionEdit,
        layout: "/admin",
        access_level: "2",
        invisible: true,
      },
    ],
  },
];

export default employee_routes;
