import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import NotificationAlert from "react-notification-alert";
import { preventDefault } from "@fullcalendar/core";

import { MEAuthRoutes } from "../../../endpoints/base.endpoint";

function Login() {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error1, setError] = useState("");
  const [showerr, setShowerr] = useState(false);
  const notificationAlertRef = React.useRef(null);

  let option_empty_field = {
    place: "tc",
    message: (
      <div className="alert-text">
        <span className="alert-title p-1 " data-notify="title">
          {" "}
          Login Error
        </span>
        <span data-notify="message p-1">Please enter email and password</span>
      </div>
    ),
    type: "danger",
    icon: "ni ni-bell-55 p-1",
    autoDismiss: 7,
  };

  let option_error = {
    place: "tc",
    message: (
      <div className="alert-text">
        <span className="alert-title p-1 " data-notify="title">
          {" "}
          Login Error
        </span>
        <span data-notify="message p-1"></span>
      </div>
    ),
    type: "danger",
    icon: "ni ni-bell-55 p-1",
    autoDismiss: 7,
  };

  const config = {
    header: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const config_exec = {
    email: email,
    password: password,
  };

  const [{ data: putData, loading: putLoading, error: putError }, executePut] =
    MEAuthRoutes(
      {
        url: "login",
        method: "POST",
        headers: config.header,
        data: config_exec,
      },
      { manual: true }
    );

  async function updateData() {
    console.log("updateData");
    if (password && email) {
      try {
        await executePut();
      } catch (error) {
        setError(error.message);
        option_error.message = (
          <div className="alert-text">
            <span className="alert-title p-1 " data-notify="title">
              {" "}
              Login Error
            </span>
            <span data-notify="message p-1">{error.message}</span>
          </div>
        );
        notificationAlertRef.current.notificationAlert(option_error);
      }
      if (putData) {
        console.log("Logged In");
      }
    } else {
      setError("Please enter email and password");

      option_empty_field.message = (
        <div className="alert-text">
          <span className="alert-title p-1 " data-notify="title">
            {" "}
            Login Error
          </span>
          <span data-notify="message p-1">Please enter email and password</span>
        </div>
      );
      notificationAlertRef.current.notificationAlert(option_empty_field);
    }
  }

  if (putData) {
    console.log("Logged In");
    console.log(putData.token);
    sessionStorage.setItem("accessToken", putData.token);
    sessionStorage.setItem("email", putData.email);
    sessionStorage.setItem("name", putData.name);
    sessionStorage.setItem("user_id", putData._id);
    sessionStorage.setItem("access_level", putData.loginType);
    // window.location.href = '/fuel-station';
    if (putData.loginType === "1") {
      window.location.href = "/admin/station-list";
    }
    if (putData.loginType === "2") {
      window.location.href = "/admin/station-list";
    }
    if (putData.loginType === "3") {
      window.location.href = "/admin/work-order-list";
    }
  }
  return (
    <>
      <AuthHeader title="Welcome" lead="" />

      <NotificationAlert
        ref={notificationAlertRef}
        zIndex={9999}
        onClick={() => console.log("hey")}
      />
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-2">
                <div className="text-muted text-center mt-2 mb-3">
                  <h3>Sign in</h3>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <Form
                  role="form"
                  onSubmit={(e) => {
                    preventDefault(e);
                  }}
                >
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onChange={(event) => setEmail(event.target.value)}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={(event) => setPassword(event.target.value)}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="default"
                      type="submit"
                      // onSubmit={updateData}
                      onClick={updateData}
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
