import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
// work order component
import Select from "react-select";
import ReactDatetime from "react-datetime";
import InspectionRow from "../../../components/Inspection/InspectionRow";
import StepWizard from "react-step-wizard";
import InspectionDropzone from "../../../components/Inspection/InspectionDropzone";
import { Link } from "react-router-dom";
import { employeeRoutes } from "../../../endpoints/base.endpoint";
import * as employeeEndpoints from "../../../endpoints/employee.endpoint";

function InspectionCreate(key, value) {
  const access_level = sessionStorage.getItem("access_level");
  let newInspectionValue = {};

  newInspectionValue = {
    engineerName: sessionStorage.getItem("name"),
    inspectionDatePreset: moment().format("YYYY-MM-DD"),
    engineerAssigned: sessionStorage.getItem("user_id"),
  };

  const [NewInspectionObject, setNewInspectionObject] = useState({});
  const [inspectionStatus, setInspectionStatus] = useState();
  const [disabledButton, setDisabledButton] = useState({
    d: false,
    b: false,
    t: false,
  });

  useEffect(() => {
    console.log(NewInspectionObject);

    sessionStorage.setItem("newInsp", JSON.stringify(NewInspectionObject));
  }, [NewInspectionObject]);

  const handleNewInsp = (typeofObj, value) => {
    if (typeofObj.includes("comment")) {
      setNewInspectionObject((prevComments) => ({
        ...prevComments,
        [typeofObj]: value,
      }));
    }

    newInspectionValue[typeofObj] = value;
    setNewInspectionObject((NewInspectionObject) => ({
      ...NewInspectionObject,
      ...newInspectionValue,
    }));
  };

  // if comments required and
  // user don't add comments
  // Next & Submit button will be disabled

  useEffect(() => {
    // convert object to array
    const ArrOfNewInspectionObj = Object.entries(NewInspectionObject);

    const ArrOfResults = ArrOfNewInspectionObj.filter((inspections) =>
      inspections[0].includes("status")
    );
    console.log(ArrOfResults, "ArrOfResults");

    const overAllStaus = ArrOfResults.every(
      (inspection) => inspection[1] === "Pass"
    );

    if (overAllStaus) {
      setInspectionStatus(true);
    } else {
      setInspectionStatus(false);
    }

    // filter out Dispenser's all inspection status
    const arrOfComments_D = ArrOfNewInspectionObj.filter((obj) =>
      obj[0].includes("complete_D")
    );
    // check if all inspection is complete
    const isAnyCommentEmpty_D = arrOfComments_D.every(
      (comment) => comment[1] === true
    );
    // if any inspection is incomplete disable Next & Submit button
    if (isAnyCommentEmpty_D) {
      setDisabledButton((disabledButton) => ({ ...disabledButton, d: false }));
    } else {
      setDisabledButton((disabledButton) => ({ ...disabledButton, d: true }));
    }

    // filter out Tank's all inspection status
    const arrOfComments_B = ArrOfNewInspectionObj.filter((obj) =>
      obj[0].includes("complete_B")
    );
    // check if all inspection is complete
    const isAnyCommentEmpty_B = arrOfComments_B.every(
      (comment) => comment[1] === true
    );
    // if any inspection is incomplete disable Next & Submit button
    if (isAnyCommentEmpty_B) {
      setDisabledButton((disabledButton) => ({ ...disabledButton, b: false }));
    } else {
      setDisabledButton((disabledButton) => ({ ...disabledButton, b: true }));
    }

    const arrOfComments_T = ArrOfNewInspectionObj.filter((obj) =>
      obj[0].includes("complete_T")
    );

    // check if all inspection is complete
    const isAnyCommentEmpty_T = arrOfComments_T.every(
      (comment) => comment[1] === true
    );
    // if any inspection is incomplete disable Next & Submit button
    if (isAnyCommentEmpty_T) {
      setDisabledButton((disabledButton) => ({ ...disabledButton, t: false }));
    } else {
      setDisabledButton((disabledButton) => ({ ...disabledButton, t: true }));
    }
  }, [NewInspectionObject]);
  console.log("Inspection Status", inspectionStatus);
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  Routes = employeeRoutes;
  endpoints = employeeEndpoints;

  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshgetStation,
  ] = Routes(
    {
      url: endpoints.list_station.path,
      method: endpoints.list_station.method,
      headers: config.header,
    },
    { manual: false }
  );

  let StationList = [];
  let assignUserValue = {};
  const [assignUserObject, setassignUserObject] = useState();
  if (getStation) {
    getStation.data.map((data, idx) =>
      StationList.push({ value: data, label: data.stationName })
    );

    // console.log(StationList);
  }

  const [
    { data: createInspection, loading: createILoading, error: createIError },
    createInspectionRequest,
  ] = Routes(
    {
      url: endpoints.create_inspection.path,
      method: endpoints.create_inspection.method,
      headers: config.header,
      data: { ...NewInspectionObject, inspectionStatus: inspectionStatus },
    },
    { manual: true }
  );

  function handleNewInspSubmit() {}
  const [SW, updateState] = useState({
    form: {},
  });
  function onStepChange(step) {
    const formtop = document.getElementById("form-top");
    formtop.scrollIntoView(false, { behavior: "smooth" });
  }

  // {status_D1,status_D2,status_D3,status_D4,status_D5,status_D6,status_D7,status_D8,status_D9,status_D10,status_D11,status_D12,status_D13,status_D14,status_T1,status_T2,status_T3,status_T4,status_T5,status_T6,status_T7,status_T8,status_T9,status_T10,status_B1,status_B2,status_B3,status_B4,status_B5,status_B6,status_B7,status_B8,status_B9,status_B10,status_B11,status_B12,status_B15,label_D1,label_D2,label_D3,label_D4,label_D5,label_D6,label_D7,label_D8,label_D9,label_D10,label_D11,label_D12,label_D13,label_D14,label_T1,label_T2,label_T3,label_T4,label_T5,label_T6,label_T7,label_T8,label_T9,label_T10,label_B1,label_B2,label_B3,label_B4,label_B5,label_B6,label_B7,label_B8,label_B9,label_B10,label_B11,label_B12,label_B15,comment_D1,comment_D2,comment_D3,comment_D4,comment_D5,comment_D6,comment_D7,comment_D8,comment_D9,comment_D10,comment_D11,comment_D12,comment_D13,comment_D14,comment_T1,comment_T2,comment_T3,comment_T4,comment_T5,comment_T6,comment_T7,comment_T8,comment_T9,comment_T10,comment_B1,comment_B2,comment_B3,comment_B4,comment_B5,comment_B6,comment_B7,comment_B8,comment_B9,comment_B10,comment_B11,comment_B12,comment_B15}

  function checkStationSetAndNext() {
    if (NewInspectionObject.stationId) {
      SW.nextStep();
    }
  }

  function checkAllTestSetAndAPI() {
    if (
      NewInspectionObject.status_D1 &&
      NewInspectionObject.status_D2 &&
      NewInspectionObject.status_D3 &&
      NewInspectionObject.status_D4 &&
      NewInspectionObject.status_D5 &&
      NewInspectionObject.status_D6 &&
      NewInspectionObject.status_D7 &&
      NewInspectionObject.status_D8 &&
      NewInspectionObject.status_D9 &&
      NewInspectionObject.status_D10 &&
      NewInspectionObject.status_D11 &&
      NewInspectionObject.status_D12 &&
      NewInspectionObject.status_D13 &&
      NewInspectionObject.status_D14 &&
      NewInspectionObject.status_T1 &&
      NewInspectionObject.status_T2 &&
      NewInspectionObject.status_T3 &&
      NewInspectionObject.status_T4 &&
      NewInspectionObject.status_T5 &&
      NewInspectionObject.status_T6 &&
      NewInspectionObject.status_T7 &&
      NewInspectionObject.status_T8 &&
      NewInspectionObject.status_T9 &&
      NewInspectionObject.status_T10 &&
      NewInspectionObject.status_T11 &&
      NewInspectionObject.status_T12 &&
      NewInspectionObject.status_B1 &&
      NewInspectionObject.status_B2 &&
      NewInspectionObject.status_B3 &&
      NewInspectionObject.status_B4 &&
      NewInspectionObject.status_B5 &&
      NewInspectionObject.status_B6 &&
      NewInspectionObject.status_B7 &&
      NewInspectionObject.status_B8 &&
      NewInspectionObject.status_B9
    ) {
      SW.nextStep();
      createInspectionRequest();
    } else {
      alert("Please fill all the fields");
    }
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Create Inspection" parentName="Inspection" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Create Inspection</h3>
          </CardHeader>
          <CardBody>
            <Form>
              <StepWizard instance={updateState} onStepChange={onStepChange}>
                <div>
                  {NewInspectionObject.stationId && (
                    <Row
                      className={"align-content-center p-3 d-flex"}
                      id={"form-top"}
                    >
                      <Col xs="6" className={"justify-content-start d-flex"}>
                        <Button
                          type={"button"}
                          color="danger"
                          onClick={SW.previousStep}
                        >
                          Go Back
                        </Button>
                      </Col>

                      <Col xs="6" className={"justify-content-end d-flex"}>
                        <Button
                          type={"button"}
                          color="success"
                          onClick={SW.nextStep}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <h6 className="heading-small text-muted mb-4">
                    Station And Other information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="u_id">
                            Station To Assign
                          </label>
                          <Select
                            options={StationList}
                            id={"station"}
                            onChange={(e) => {
                              handleNewInsp("stationId", e.value._id);
                              handleNewInsp("stationName", e.value.stationName);
                              handleNewInsp(
                                "stationAddress",
                                e.value.stationAddress
                              );
                            }}
                            required
                          />
                          {NewInspectionObject.stationAddress && (
                            <div className="mt-1">
                              <span className="form-control-label">
                                Station Address:{" "}
                              </span>
                              {NewInspectionObject.stationAddress}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-date"
                          >
                            Date
                          </label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "Date Picker Here",
                                disabled: true,
                              }}
                              value={moment(new Date()).format("MM-DD-YYYY")}
                              timeFormat={false}
                              onChange={(e) => {
                                handleNewInsp(
                                  "inspectionDate",
                                  moment(e).format("YYYY-MM-DD")
                                );
                                console.log(moment(e).format("YYYY-MM-DD"));
                                checkStationSetAndNext();
                              }}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div>
                  <Row
                    className={"align-content-center p-3 d-flex"}
                    id={"form-top"}
                  >
                    <Col xs="6" className={"justify-content-start d-flex"}>
                      <Button
                        type={"button"}
                        color="danger"
                        onClick={SW.previousStep}
                      >
                        Go Back
                      </Button>
                    </Col>

                    <Col xs="6" className={"justify-content-end d-flex"}>
                      <Button
                        type={"button"}
                        color="success"
                        onClick={SW.nextStep}
                        disabled={disabledButton.d}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Dispenser Check List
                  </h6>
                  <h4>
                    <ListGroup>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D1"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"HOSE IN GOOD CONDITION?"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D2"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"NOZZLES IN GOOD CONDITION?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D3"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"BREAKAWAYS IN GOOD CONDITION?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D4"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"SWIVEL IN GOOD CONDITION?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D5"}
                          onChange={handleNewInsp}
                          inverse={true}
                          label={"ARE HOSE TOUCHING THE GROUND?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D6"}
                          onChange={handleNewInsp}
                          inverse={true}
                          label={"ANY LEAKING HOSE OR NOZZLE?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D7"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={
                            "ARE NOZZLE OPERATING INSTRUCTION ON DISPENSER?\n"
                          }
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D8"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE DISPENSER DISPLAYS OPERATING?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D9"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE STATE CALIBRATION STICKERS PRESENT?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D10"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE SHEAR VALVES THERE?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D11"}
                          onChange={handleNewInsp}
                          inverse={true}
                          label={"ARE THEY LEAKING?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D12"}
                          onChange={handleNewInsp}
                          inverse={true}
                          label={"ARE THEY ANCHORED?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D13"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE CALIBRATION SEAL INSIDE DISPENSER?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"D14"}
                          onChange={handleNewInsp}
                          inverse={true}
                          label={"ANY LEAK INSIDE DISPENSER?\n"}
                        />
                      </ListGroupItem>
                    </ListGroup>
                    <Row className={"align-content-center p-3 d-flex"}>
                      <Col xs="6" className={"justify-content-start d-flex"}>
                        <Button
                          type={"button"}
                          color="danger"
                          onClick={SW.previousStep}
                        >
                          Go Back
                        </Button>
                      </Col>

                      <Col xs="6" className={"justify-content-end d-flex"}>
                        <Button
                          type={"button"}
                          color="success"
                          onClick={(e) => {
                            SW.nextStep();
                          }}
                          disabled={disabledButton.d}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </h4>
                </div>
                <div>
                  <Row
                    className={"align-content-center p-3 d-flex"}
                    id={"form-top"}
                  >
                    <Col xs="6" className={"justify-content-start d-flex"}>
                      <Button
                        type={"button"}
                        color="danger"
                        onClick={SW.previousStep}
                      >
                        Go Back
                      </Button>
                    </Col>

                    <Col xs="6" className={"justify-content-end d-flex"}>
                      <Button
                        type={"button"}
                        color="success"
                        onClick={SW.nextStep}
                        disabled={disabledButton.t}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Tank Check List
                  </h6>
                  <h4>
                    <ListGroup>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T1"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE ALL MANHOLE LIDS ON THE TANKS?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T2"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE MANHOLE LIDS IN GOOD CONDITION?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T3"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE FILL CAP ON AND SEALING?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T4"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS THERE A DROP TUBE INSTALLED?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T5"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS THERE A DRY BREAK OR VAPOR ADAPTER?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T6"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS VAPOR CAP ON & SEALING?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T7"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"LEAK DETECTOR PRESENT?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T8"}
                          onChange={handleNewInsp}
                          inverse={true}
                          label={"ANY LEAK AT THE SUB-PUMP?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T9"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS SPILL BUCKET IN GOOD CONDITION?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T10"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS SPILL BUCKET FREE OF CRACK OR HOLES?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T11"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE DRAIN VALVES OPERATIONAL?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"T12"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={
                            "ARE SPILL CONTAINERS LIQUID TIGHT / FREE OF DEBRIS\n"
                          }
                        />
                      </ListGroupItem>
                    </ListGroup>
                    <Row className={"align-content-center p-3 d-flex"}>
                      <Col xs="6" className={"justify-content-start d-flex"}>
                        <Button
                          type={"button"}
                          color="danger"
                          onClick={SW.previousStep}
                        >
                          Go Back
                        </Button>
                      </Col>

                      <Col xs="6" className={"justify-content-end d-flex"}>
                        <Button
                          type={"button"}
                          color="success"
                          onClick={SW.nextStep}
                          disabled={disabledButton.t}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </h4>
                </div>
                <div>
                  <Row
                    className={"align-content-center p-3 d-flex"}
                    id={"form-top"}
                  >
                    <Col xs="6" className={"justify-content-start d-flex"}>
                      <Button
                        type={"button"}
                        color="danger"
                        onClick={SW.previousStep}
                      >
                        Go Back
                      </Button>
                    </Col>

                    <Col xs="6" className={"justify-content-end d-flex"}>
                      {/*<Button
                        type={"button"}
                        color="success"
                        onClick={SW.nextStep}
                      >
                        Next
                      </Button>*/}
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    TCEQ Book Check List
                  </h6>
                  <h4>
                    <ListGroup>
                      {/* <ListGroupItem>
                        <InspectionRow
                          id={"B1"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS THE BOOK ONSITE?\n"}
                        />
                      </ListGroupItem> */}
                      <ListGroupItem>
                        <InspectionRow
                          id={"B1"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE DAILY INSPECTIONS UP TO DATE?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"B2"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE MONTHLY INSPECTIONS UP TO DATE?\n"}
                        />
                      </ListGroupItem>
                      {/* <ListGroupItem>
                        <InspectionRow
                          id={"B4"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"DOES THE CARB MATCH THE SYSTEM?\n"}
                        />
                      </ListGroupItem> */}
                      <ListGroupItem>
                        <InspectionRow
                          id={"B3"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE THERE CERTIFICATES (STAGE II & A&B)?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"B4"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE EMPLOYEE TRAINED: STAGE II & A&B?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"B5"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS TANK CERTIFICATE POSTED?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"B6"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"DO THEY HAVE INSURANCE?\n"}
                        />
                      </ListGroupItem>
                      {/* <ListGroupItem>
                        <InspectionRow
                          id={"B9"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS THE DELIVERY TICKET POSTED?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"B10"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS THE ANNUAL PRESSURE DECAY IN BOOK?\n"}
                        />
                      </ListGroupItem> */}
                      <ListGroupItem>
                        <InspectionRow
                          id={"B7"}
                          onChange={handleNewInsp}
                          inverse={false}
                          // label={"ARE LINE & LEAK DETECTOR TEST THERE?\n"}
                          label={"IS ANNUAL TEST CURRENT?\n"}
                        />
                      </ListGroupItem>
                      <ListGroupItem>
                        <InspectionRow
                          id={"B8"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS AGRICULTURE CERTIFICATE POSTED\n"}
                        />
                      </ListGroupItem>
                      {/* <ListGroupItem>
                        <InspectionRow
                          id={"B13"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"IS MONTHLY ELECTRONIC LINE TEST IN BOOK?\n"}
                        />
                      </ListGroupItem> */}
                      <ListGroupItem>
                        <InspectionRow
                          id={"B9"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"ARE THEY DOING DAILY RECONCILIATION?\n"}
                        />
                      </ListGroupItem>
                      {/* <ListGroupItem>
                        <InspectionRow
                          id={"B15"}
                          onChange={handleNewInsp}
                          inverse={false}
                          label={"DID YOU COLLECT THE SIR SHEET?\n"}
                        />
                      </ListGroupItem> */}
                    </ListGroup>
                    <Row className={"align-content-center p-3 d-flex"}>
                      <Col xs="6" className={"justify-content-start d-flex"}>
                        <Button
                          type={"button"}
                          color="danger"
                          onClick={SW.previousStep}
                        >
                          Go Back
                        </Button>
                      </Col>

                      <Col xs="6" className={"justify-content-end d-flex"}>
                        <Button
                          type={"button"}
                          color="success"
                          onClick={checkAllTestSetAndAPI}
                          disabled={disabledButton.b}
                        >
                          Save & Upload Images
                        </Button>
                      </Col>
                    </Row>
                  </h4>
                </div>
                <div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Upload Inspection Images (Min 1)
                  </h6>
                  {createInspection && (
                    <InspectionDropzone
                      insp_id={createInspection.inspectionID}
                    />
                  )}
                  <Row>
                    <Col sm={6}>
                      <Button type={"button"} color="success">
                        <Link to={"./inspection-list"}>Finish</Link>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </StepWizard>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default InspectionCreate;
