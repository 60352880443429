import React from "react";
import { Col, Label, ListGroupItem } from "reactstrap";

function InspectionViewRow(props) {
  const label = props.label;
  const status = props.status;
  const comment = props.comment;
  return (
    <ListGroupItem className="row">
      <Col
        xs={12}
        md={12}
        className=" d-flex justify-content-between align-items-center"
      >
        {label} :<b>{status}</b>
      </Col>
      {comment && (
        <Col xs={12} md={12} className={"m-2"}>
          <Label>Comments:</Label>
          <b className={"of-wrap"}>{comment}</b>
        </Col>
      )}
    </ListGroupItem>
  );
}

export default InspectionViewRow;
