import React, { useCallback, useEffect, useState } from "react";
import { s3Client, BUCKET_NAME } from "../../../clients/S3_client";
import {
  GetObjectCommand,
  ListObjectsCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";

import {
  setChonkyDefaults,
  FullFileBrowser,
  ChonkyActions,
  defineFileAction,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import path from "path-browserify";
import { Container } from "reactstrap";
import fileDownload from "js-file-download";
// import OpenFilesPayload from "chonky/src/types/action-payloads.types";
// Somewhere in your `index.ts`:
setChonkyDefaults({ iconComponent: ChonkyIconFA });

const fetchS3BucketContents = async (bucket, prefix) => {
  try {
    return await s3Client
      .listObjectsV2({
        Bucket: bucket,
        Delimiter: "/",
        Prefix: prefix !== "/" ? prefix : "",
      })
      .then((response) => {
        console.log(response);
        const chonkyFiles = [];
        const s3Objects = response.Contents;
        const s3Prefixes = response.CommonPrefixes;

        if (s3Objects) {
          chonkyFiles.push(
            ...s3Objects.map((object) => ({
              id: object.Key,
              name: path.basename(object.Key),
              modDate: object.LastModified,
              size: object.Size,
            }))
          );
        }

        if (s3Prefixes) {
          chonkyFiles.push(
            ...s3Prefixes.map((prefix) => ({
              id: prefix.Prefix,
              name: path.basename(prefix.Prefix),
              isDir: true,
            }))
          );
        }

        return chonkyFiles;
      });
  } catch (e) {
    console.log(e);
  }
};

function Test(props) {
  const [error, setError] = useState(null);
  const [folderPrefix, setKeyPrefix] = useState("/");
  const [files, setFiles] = useState([]);
  const myFileActions = [ChonkyActions.OpenFiles, ChonkyActions.DownloadFiles];

  useEffect(() => {
    fetchS3BucketContents(BUCKET_NAME, folderPrefix)
      .then(setFiles)
      .catch((error) => setError(error.message));
  }, [folderPrefix, setFiles]);

  const folderChain = React.useMemo(() => {
    let folderChain = [];
    if (folderPrefix === "/") {
      folderChain = [];
    } else {
      let currentPrefix = "";
      folderChain = folderPrefix
        .replace(/\/*$/, "")
        .split("/")
        .map((prefixPart) => {
          currentPrefix = currentPrefix
            ? path.join(currentPrefix, prefixPart)
            : prefixPart;
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
          };
        });
    }
    folderChain.unshift({
      id: "/",
      name: BUCKET_NAME,
      isDir: true,
    });
    return folderChain;
  }, [folderPrefix]);

  const down = defineFileAction({
    id: "execute_order_66",
    hotkeys: ["ctrl+o"],
    button: {
      name: "Execute Order 66",
      toolbar: true,
      contextMenu: true,
    },
  });

  const handleFileAction = useCallback(
    (data) => {
      //for open file and folder
      if (data.id === ChonkyActions.OpenFiles.id) {
        if (data.payload.targetFile && !data.payload.targetFile.isDir) {
          console.log("download file");
          const params = {
            Bucket: BUCKET_NAME,
            Key: data.payload.targetFile.id,
          };
          s3Client.send(new GetObjectCommand(params)).then((d) => {
            fileDownload(d.Body, data.payload.targetFile.name);
          });
          return;
        }

        if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;
        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`;
        console.log(`Key prefix: ${newPrefix}`);
        setKeyPrefix(newPrefix);
      }

      if (data.id === down.id) {
        console.log("download file dd");
        const params = {
          Bucket: BUCKET_NAME,
          Key: data.payload.targetFile.id,
        };
        s3Client.send(new GetObjectCommand(params)).then((d) => {
          fileDownload(d.Body, data.payload.targetFile.name);
        });
        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`;
        console.log(`Key prefix: ${newPrefix}`);
        setKeyPrefix(newPrefix);
      }
    },
    [setKeyPrefix]
  );
  return (
    <>
      <Container fluid={true}>
        <FullFileBrowser
          files={files}
          folderChain={folderChain}
          clearSelectionOnOutsideClick={true}
          fileActions={myFileActions}
          onFileAction={handleFileAction}
        />
      </Container>
    </>
  );
}

export default Test;