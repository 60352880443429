import React, { useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  employeeRoutes,
  managerRoutes,
} from "../../../endpoints/base.endpoint";
import * as managerEndpoints from "../../../endpoints/manager.endpoint";
import * as employeeEndpoints from "../../../endpoints/employee.endpoint";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
            defaultValue={"25"}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function StationList() {
  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);
  const access_level = sessionStorage.getItem("access_level");
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  if (access_level === "1") {
    Routes = managerRoutes;
    endpoints = managerEndpoints;
  } else if (access_level === "2") {
    Routes = employeeRoutes;
    endpoints = employeeEndpoints;
  }
  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshStation,
  ] = Routes(
    {
      url: endpoints.list_station.path,
      method: endpoints.list_station.method,
      headers: config.header,
    },
    { manual: false }
  );
  if (getStation) {
    console.log(getStation);
    getStation.data.map(
      (data, idx) =>
        (data.btn = (
          <a
            href={"./station-details"}
            className="btn btn-success  btn-sm  d-print-none"
            onClick={(e) =>
              sessionStorage.setItem("station_manage_id", data._id)
            }
          >
            Details
          </a>
        ))
    ); //to add details button in the table
    getStation.data.map(
      (data, idx) =>
        (data.fm_btn = (
          <a
            href={"./station-files"}
            className="btn btn-success  btn-sm  d-print-none"
            onClick={(e) => {
              sessionStorage.setItem("station_file_manage_id", data._id);
              sessionStorage.setItem(
                "station_file_manage_name",
                data.stationName
              );
            }}
          >
            Files
          </a>
        ))
    ); //to add file manager button
  }
  const managerColumns = [
    {
      dataField: "stationName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "stationAddress",
      text: "Address",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: false,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: false,
    },

    {
      dataField: "fm_btn",
      text: "Files",
      sort: false,
      headerStyle: { textAlign: "center" },
      style: {
        textAlign: "center",
      },
    },
    {
      dataField: "btn",
      text: "Action",
      sort: false,
      headerStyle: { textAlign: "center" },
      style: {
        textAlign: "center",
      },
    },
  ];
  const employeeColumns = [
    {
      dataField: "stationName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "stationAddress",
      text: "Address",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: false,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: false,
    },

    {
      dataField: "btn",
      text: "Action",
      sort: false,
      headerStyle: { textAlign: "center" },
      style: {
        textAlign: "center",
      },
    },
  ];
  let tableColumns = [];
  if (access_level === "1") {
    tableColumns = managerColumns;
  } else if (access_level === "2") {
    tableColumns = employeeColumns;
  }
  useEffect(() => {
    console.log(getStation);
  }, [getStation]);
  return (
    <>
      {alert}
      <SimpleHeader
        name="Station List"
        // parentName="Station"
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              {/* <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Station List</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader> */}

              {getStation && (
                <ToolkitProvider
                  data={getStation.data}
                  keyField="_id"
                  columns={tableColumns}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="primary"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={6}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default StationList;
