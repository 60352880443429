import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
// import CustomProfileHeader from "components/Headers/CustomProfileHeader.js";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import {
  employeeRoutes,
  managerRoutes,
} from "../../../endpoints/base.endpoint";
import * as managerEndpoints from "../../../endpoints/manager.endpoint";
import * as employeeEndpoints from "../../../endpoints/employee.endpoint";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  near_and_expired,
  near_and_expired_by_id,
} from "../../../endpoints/manager.endpoint";
import { object } from "prop-types";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function StationDetails() {
  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };
  const access_level = sessionStorage.getItem("access_level");
  const station_manage_id = sessionStorage.getItem("station_manage_id");
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };
  console.log(station_manage_id);
  let Routes;
  let endpoints;
  if (access_level === "1") {
    Routes = managerRoutes;
    endpoints = managerEndpoints;
  } else if (access_level === "2") {
    Routes = employeeRoutes;
    endpoints = employeeEndpoints;
  }
  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshStation,
  ] = Routes(
    {
      url: endpoints.view_station.path,
      method: endpoints.view_station.method,
      headers: config.header,
      data: { id: station_manage_id },
    },
    { manual: false }
  );
  const [
    {
      data: getNearAndExpired,
      loading: getNearAndExpiredLoading,
      error: getNearAndExpiredError,
    },
    refreshNearAndExpired,
  ] = Routes(
    {
      url: managerEndpoints.near_and_expired_by_id.path,
      method: managerEndpoints.near_and_expired_by_id.method,
      headers: config.header,
      data: { sid: station_manage_id },
    },
    { manual: false }
  );

  if (getNearAndExpired) {
    console.log(getNearAndExpired);
    //to add details button in the table
    getNearAndExpired.map(
      (data, idx) =>
        (data.fm_btn = (
          <a
            href={"./station-files"}
            className="btn btn-success  btn-sm  d-print-none"
            onClick={(e) => {
              sessionStorage.setItem("station_file_manage_id", data.stationId);
              sessionStorage.setItem(
                "station_file_manage_name",
                data.stationName
              );
            }}
          >
            Files
          </a>
        ))
    ); //to add file manager button
  }
  if (getStation) {
    console.log(getStation);
  }
  return (
    <>
      {/* <CustomProfileHeader heading={"Station Details"} /> */}
      <SimpleHeader
        name="Station Details"
        // parentName="Station"
      />
      {getStation && (
        <Container className="mt--6" fluid>
          <Card className="card-profile">
            <CardBody className="border-0 p-4 p-md-3 ">
              <Row>
                <a href="./station-list" className="text-white">
                  <Button size="sm" color="default" className="mx-4">
                    Back
                  </Button>
                </a>
              </Row>
              <Row>
                <Col className="order-lg-1" lg="12">
                  <div className="d-flex justify-content-center">
                    <span className={"display-1"}>
                      {getStation.stationName}
                    </span>
                  </div>
                </Col>
                <Col className={"order-lg-2"}>
                  <>
                    {getStation && (
                      <div className="p-1">
                        {/* <Row>
                          <div className="col-12">
                            <div className="card-profile-stats d-flex justify-content-center"></div>
                          </div>
                        </Row> */}
                        <Row className="p-4">
                          <Col lg="12" className="h1 mt-2">
                            <span className="font-weight-light">
                              {" "}
                              {getStation.stationAddress},
                            </span>
                            <span className="font-weight-light ">
                              {" "}
                              {getStation.city} , {getStation.state}
                            </span>
                            <br />
                            <span className="font-weight-light ">
                              {" "}
                              {getStation.zip}
                            </span>
                          </Col>
                          {getStation.phone && (
                            <Col lg={"6"} sm={"12"} className=" mt-2">
                              <span className="h4 font-weight-light ">
                                {" "}
                                Phone
                              </span>{" "}
                              :{" "}
                              <a
                                className={"h3"}
                                href={"tel:" + getStation.phone}
                              >
                                {getStation.phone}
                              </a>
                            </Col>
                          )}
                          {getStation.email && (
                            <Col lg="6" sm={"12"} className=" mt-2">
                              <span className="h4 font-weight-light ">
                                {" "}
                                Email
                              </span>{" "}
                              :{" "}
                              <a
                                href={"mailto:" + getStation.email}
                                className={"h3"}
                              >
                                {getStation.email}
                              </a>
                            </Col>
                          )}
                          {getStation.stationContact && (
                            <Col lg="6" sm={"12"} className={" mt-2"}>
                              <span className={"h4 font-weight-light"}>
                                Station Contact :
                              </span>{" "}
                              <span className={"h3"}>
                                {getStation.stationContact}
                              </span>
                            </Col>
                          )}
                          {getStation.facilityId && (
                            <Col lg="6" sm={"12"} className={" mt-2"}>
                              <span className={"h4 font-weight-light "}>
                                Facility Id :{" "}
                              </span>
                              <span className={"h3"}>
                                {getStation.facilityId}
                              </span>
                            </Col>
                          )}
                          {getStation.ownerId && (
                            <Col lg="6" sm={"12"} className={" mt-2"}>
                              <span className={"h4 font-weight-light "}>
                                Owner Id :{" "}
                              </span>{" "}
                              <span className={"h3"}>{getStation.ownerId}</span>
                            </Col>
                          )}
                          {getStation.consoleType && (
                            <Col lg="6" sm={"12"} className={" mt-2"}>
                              <span className={"h4 font-weight-light "}>
                                Console Type :{" "}
                              </span>{" "}
                              <span className={"h3"}>
                                {getStation.consoleType}
                              </span>
                            </Col>
                          )}
                          {getStation.dispenserType && (
                            <Col lg="6" sm={"12"} className={" mt-2"}>
                              <span className={"h4 font-weight-light "}>
                                Dispenser Type :{" "}
                              </span>{" "}
                              <span className={"h3"}>
                                {getStation.dispenserType}
                              </span>
                            </Col>
                          )}
                          {getStation.tank &&
                            Object.entries(getStation.tank).map((tank) => (
                              <Col lg="6" sm={"12"} className={" mt-2"}>
                                <span className={"h4 font-weight-light"}>
                                  Tank# {tank[0]} :{" "}
                                  <span className={"h3"}>{tank[1].type}</span>
                                </span>{" "}
                                <span className={"h3"}>{getStation.tank1}</span>
                              </Col>
                            ))}
                        </Row>
                      </div>
                    )}
                  </>
                </Col>

                {access_level === "1" && getNearAndExpired && (
                  <Col lg={"6"} md={"12"} className={"order-lg-3"}>
                    <>
                      <div>
                        <h3 className="mb-0">
                          Station Files Near Expiry and Expired
                        </h3>
                      </div>
                      <div className="p-1">
                        <ToolkitProvider
                          data={getNearAndExpired}
                          keyField="_id"
                          columns={[
                            {
                              dataField: "fileName",
                              text: "File Name",
                              sort: true,
                            },
                            {
                              dataField: "expiry",
                              text: "Expiry",
                              sort: true,
                            },
                            {
                              dataField: "fm_btn",
                              text: "Files",
                              sort: false,
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 px-4 table-responsive">
                              <Container fluid>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="primary"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          copyToClipboardAsTable(
                                            document.getElementById(
                                              "react-bs-table"
                                            )
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="primary"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1 float-right"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                              <BootstrapTable
                                ref={componentRef}
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={true}
                                striped
                                hover
                                condensed
                                id="react-bs-table"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
}

export default StationDetails;
