//list endpoints
export const list_station = { path: "getAllStations", method: "get" };
export const list_work_order = { path: "getAllWorkOrders", method: "get" };
export const list_inspection = { path: "getAllInspections", method: "get" };

// edit and create endpoints
export const modify_work_order = "";
export const update_status_work_order = {
  path: "completeWorkOrder",
  method: "post",
};

export const create_inspection = { path: "createInspection", method: "post" };
export const edit_inspection = {
  path: "editInspection",
  method: "post",
};

//view individual endpoints
export const view_inspection = { path: "getInspectionById", method: "post" };
export const view_work_order = { path: "getWorkOrderById", method: "post" };
export const view_station = { path: "getStationById", method: "post" };

// upload inspection files endpoints
export const upload_inspection_files = "";
