import React from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  ListGroup,
  Row,
} from "reactstrap";
import petrology_solutions from "../../assets/img/petrology_solutions.png";
import InspectionViewRow from "./InspectionViewRow";
import { current_host } from "../../endpoints/base.endpoint";
import moment from "moment";

const InspectionViewComp = (props, ref) => {
  let inspection;
  let station;
  if (props.props) {
    inspection = props.props.inspection;
    station = props.props.station;
  }
  console.log(inspection);
  if (inspection !== undefined) {
    const stationName = inspection.stationName ? inspection.stationName : "";
    const stationAddress = inspection.stationAddress
      ? inspection.stationAddress
      : "";
    const date = inspection.date ? inspection.date : "";
    const inspections = inspection.inspections ? inspection.inspections : "";
    const engineerName = inspection.engineerName ? inspection.engineerName : "";
    const images = inspection.images ? inspection.images : "";
  }

  return (
    <>
      {/* <Container className="mt--6" fluid> */}
      {/* <Card> */}
      {/* <CardBody> */}
      <div className={"page"}>
        <h3 className="mb-0 d-print-none">Monthly Inspection Checklist</h3>
        <Row className={"pt-5 d-print-flex d-none"}>
          <Col xs="4">
            <img
              src={petrology_solutions}
              className={""}
              alt={"Petrology"}
              style={{ height: "100px" }}
            />
          </Col>
          <Col xs="4">
            <h2 className={""}>Monthly Inspection Checklist</h2>
          </Col>

          <Col xs="4" className={"text-right "}>
            <h5>
              11501 Windsor Ln., # 102,
              <br />
              Houston, TX 77031.
              <br />
              Tel#: 832-500-7867 (PUMP)
              <br />
              Fax #. 1-877-277-9031
            </h5>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col xs={"4"}>
            <h5>
              <span>Site Name : {station && station.stationName}</span>
              <br />
              <span>Site Address : {station && station.stationAddress}</span>
              <br />
              <span>
                City/St./Zip :{" "}
                {station &&
                  station.city + " / " + station.state + " / " + station.zip}
              </span>
              <br />
              <span>Facility ID # : {station && station.facilityId}</span>
              <br />
              <span>Owner ID # : {station && station.ownerId}</span>
              <br />
            </h5>
          </Col>
          <Col xs={"4"}>
            <h5>
              <span>
                Tank #1 :{" "}
                {(station && station.tank1) ||
                  (station && station.tank && station.tank["01"] && station.tank["01"]["type"])}
              </span>
              <br />
              <span>
                Tank #2 :{" "}
                {(station && station.tank2) ||
                  (station && station.tank  && station.tank["02"] && station.tank["02"]["type"])}
              </span>
              <br />
              <span>
                Tank #3 :{" "}
                {(station && station.tank3) ||
                  (station && station.tank  && station.tank["03"] && station.tank["03"]["type"])}
              </span>
              <br />
              <span>
                Tank #4 :{" "}
                {(station && station.tank4) ||
                  (station && station.tank  &&
                    station.tank["04"] &&
                    station.tank["04"]["type"])}{" "}
              </span>
              <br />
              <span>
                Tank #5 :{" "}
                {(station && station.tank5) ||
                  (station && station.tank  && station.tank["05"] && station.tank["05"]["type"])}
              </span>
              <br />
            </h5>
          </Col>
          <Col xs={"4"}>
            <h5>
              <span>
                Date :{" "}
                {inspection &&
                  moment(new Date(inspection.inspectionDate)).format(
                    "MM-DD-YYYY"
                  )}
              </span>
              <br />
              <span>Site Contact : {station && station.stationContact}</span>
              <br />
              <span>Site Phone # : {station && station.phone}</span>
              <br />
              <span>ATG Type: {station && station.ATGtype} </span>
              <br />
              <span>Console Type : {station && station.consoleType}</span>
              <br />
              <span>Dispenser Type : {station && station.dispenserType} </span>
            </h5>
          </Col>
        </Row>

        <h6 className="heading-small text-muted mt-4 mb-4">
          Dispenser Check List
        </h6>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <h4>
                {inspection && (
                  <ListGroup>
                    {Object.entries(
                      inspection.inspections.Dispenser_inspection
                    ).map((inspection_d) => (
                      <InspectionViewRow
                        key={inspection_d[0]}
                        label={inspection_d[1].label}
                        status={inspection_d[1].status}
                        comment={inspection_d[1].comment}
                      />
                    ))}
                  </ListGroup>
                )}
              </h4>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <hr className="my-4" />

      <div className={"page"}>
        <h6 className="heading-small text-muted mb-4">Tank Check List</h6>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <h4>
                {inspection && (
                  <ListGroup>
                    {Object.entries(inspection.inspections.Tank_inspection).map(
                      (inspection_t) => (
                        <InspectionViewRow
                          key={inspection_t[0]}
                          label={inspection_t[1].label}
                          status={inspection_t[1].status}
                          comment={inspection_t[1].comment}
                        />
                      )
                    )}
                  </ListGroup>
                )}
              </h4>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <hr className="my-4" />
      <div className={"page"}>
        <h6 className="heading-small text-muted mb-4">TCEQ Book Check List</h6>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <h4>
                {inspection && (
                  <ListGroup>
                    {Object.entries(inspection.inspections.TCEQ_inspection).map(
                      (inspection_b) => (
                        <InspectionViewRow
                          key={inspection_b[0]}
                          label={inspection_b[1].label}
                          status={inspection_b[1].status}
                          comment={inspection_b[1].comment}
                        />
                      )
                    )}
                  </ListGroup>
                )}
              </h4>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <hr className="my-4" />
      <div className={"page"}>
        <h6 className="heading-small text-muted mb-4">Inspection Images</h6>
        <Row className={""}>
          {inspection &&
            inspection.images.map((image, index) => (
              <Col sm={3} className={"p-1"} key={index}>
                <img
                  src={
                    current_host +
                    "/uploads/inspection/" +
                    inspection._id +
                    "/" +
                    image
                  }
                  alt={image}
                  className={"img-fluid"}
                />
              </Col>
            ))}
        </Row>
        <Row className={"d-none d-print-flex row"}>
          <Col lg={4} xs={4}>
            <label className="form-control-label" htmlFor={"engineer-sign"}>
              Engineer Sign
            </label>
            <div
              className={"border-dark border p-5"}
              id={"engineer-sign"}
            ></div>
          </Col>
          <Col lg={4} xs={4}>
            <label className="form-control-label" htmlFor={"owner-sign"}>
              Store Owner Sign
            </label>
            <div className={"border-dark border p-5"} id={"owner-sign"}></div>
          </Col>
          <Col lg={4} xs={4}>
            <label className="form-control-label" htmlFor={"admin-sign"}>
              Admin Sign
            </label>
            <div className={"border-dark border p-5"} id={"admin-sign"}></div>
          </Col>
        </Row>
      </div>
      {/* </CardBody> */}
      {/* </Card> */}
      {/* </Container> */}
    </>
  );
};

export default InspectionViewComp;
