import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
// work order component
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { managerRoutes } from "../../../endpoints/base.endpoint";

function WorkOrderNew() {
  const [alert, setAlert] = React.useState(null);
  let newWorkOrderValue = {
    managerName: sessionStorage.getItem("name"),
    managerId: sessionStorage.getItem("user_id"),
    issue: "Created by Manager",
    status: "Open",
    date: moment().format("YYYY-MM-DD"),
  };
  const [NewWorkOrderObject, setNewWorkOrderObject] = useState();

  useEffect(() => {
    console.log(NewWorkOrderObject);
  }, [NewWorkOrderObject]);

  const handleNewWO = (typeofObj, value) => {
    newWorkOrderValue[typeofObj] = value;
    setNewWorkOrderObject((NewWorkOrderObject) => ({
      ...NewWorkOrderObject,
      ...newWorkOrderValue,
    }));
    console.log(NewWorkOrderObject);
  };
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshgetStation,
  ] = managerRoutes(
    {
      url: "getAllStations",
      method: "GET",
      headers: config.header,
    },
    { manual: false }
  );

  let StationList = [];
  let assignUserValue = {};
  const [assignUserObject, setassignUserObject] = useState();
  if (getStation) {
    getStation.data.map((data, idx) =>
      StationList.push({ value: data, label: data.stationName })
    );
  }

  const [
    { data: getEmp, loading: getEmpLoading, error: getEmpError },
    refreshEmp,
  ] = managerRoutes(
    {
      url: "getAllEmployees",
      method: "GET",
      headers: config.header,
    },
    { manual: false }
  );

  let EmpList = [];
  if (getEmp) {
    getEmp.map((data, idx) => EmpList.push({ value: data, label: data.name }));
  }

  const [
    { data: createWorkOrder, loading: createOLoading, error: createOError },
    createWorkOrderRequest,
  ] = managerRoutes(
    {
      url: "createWorkOrder",
      method: "POST",
      headers: config.header,
      data: NewWorkOrderObject,
    },
    { manual: true }
  );

  function handleNewWorkOrderSubmit() {
    createWorkOrderRequest().then((res) => {
      console.log(res);
      console.log("redirect");
      sessionStorage.setItem("work_order_manage_id", res.data._id);
      console.log(res.data._id);
      window.location.href = "./work-order-detail";
    });
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Create Work Order " parentName="Work Order" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Create Work Order</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station And Engineer information
              </h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Station To Assign
                      </label>
                      <Select
                        options={StationList}
                        id={"station"}
                        onChange={(e) => {
                          handleNewWO("stationId", e.value._id);
                          handleNewWO("stationName", e.value.stationName);
                          handleNewWO("stationAddress", e.value.stationAddress);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Engineer To Assign
                      </label>
                      <Select
                        options={EmpList}
                        id={"Employee"}
                        onChange={(e) => {
                          handleNewWO("engineerName", e.value.name);
                          handleNewWO("engineerAssigned", e.value._id);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />

              <h6 className="heading-small text-muted mb-4">
                Work Order information
              </h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-date"
                      >
                        Date
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Date Picker Here",
                          }}
                          value={moment(new Date()).format("YYYY-MM-DD")}
                          timeFormat={false}
                          onChange={(e) => {
                            handleNewWO("date", moment(e).format("YYYY-MM-DD"));
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-work-details"
                      >
                        Work Details
                      </label>
                      <Input
                        id="input-work-details"
                        rows="6"
                        type={"textarea"}
                        placeholder={"Work Order Details"}
                        onChange={(e) => {
                          handleNewWO("workDetails", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/*<Col lg={10} sm={10}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor={"input-comments"}
                      >
                        Comments
                      </label>
                      <Input
                        type={"textarea"}
                        rows="3"
                        name={"Comments"}
                        id={"input-comments"}
                        onChange={(e) => {
                          handleNewWO("comment", e.target.value);
                        }}
                        placeholder={"Type Comments Here"}
                      />
                    </FormGroup>
                  </Col>*/}
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button color="default" onClick={handleNewWorkOrderSubmit}>
              Create Work Order
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default WorkOrderNew;
