import axios from "axios";
import { makeUseAxios } from "axios-hooks";

const local = "http://localhost:3001";
const rpi = "http://192.168.1.3:3001";
const remote_local = "http://192.168.1.25:3001";
const remote = "https://api.tankoncloud.com";

export const remote_files = "http://157.245.94.5:3020";

export const current_host = remote;

export const baseRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/",
  }),
});
export const MEAuthRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/auth/me/",
  }),
});
export const managerRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/m/",
  }),
});

export const employeeRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/e/",
  }),
});
export const userRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/u/",
  }),
});
