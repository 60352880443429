
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

import logo from "assets/img/brand/logo.svg";
function AdminNavbar() {
  return (
      <>
        <Navbar
            className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
            expand="lg"
            id="navbar-main"
        >
          <Container>
            <NavbarBrand to="/" tag={Link}>
              <img alt="..." src={logo} />
            </NavbarBrand>
          </Container>
        </Navbar>
      </>
  );
}

export default AdminNavbar;
