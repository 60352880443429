import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import StepWizard from "react-step-wizard";
import { employeeRoutes } from "../../../endpoints/base.endpoint";
import * as employeeEndpoints from "../../../endpoints/employee.endpoint";
import InspectionEditRow from "components/Inspection/InspectionEditRow";

function InspectionEdit() {
  let editInspectionValue = {};

  const [EditInspectionObject, setEditInspectionObject] = useState({});
  const [inspectionStatus, setInspectionStatus] = useState();
  const [disabledButton, setDisabledButton] = useState({
    d: false,
    b: false,
    t: false,
  });

  const handleNewInsp = (typeofObj, value) => {
    console.log("checklist", typeofObj, "-", value);
    if (typeofObj.includes("comment")) {
      setEditInspectionObject((prevComments) => ({
        ...prevComments,
        [typeofObj]: value,
      }));
    }

    editInspectionValue[typeofObj] = value;
    setEditInspectionObject((EditInspectionObject) => ({
      ...EditInspectionObject,
      ...editInspectionValue,
    }));
  };
  console.log("inspection object", EditInspectionObject);
  // if comments required and
  // user don't add comments
  // Next & Submit button will be disabled

  useEffect(() => {
    // convert object to array
    const ArrOfNewInspectionObj = Object.entries(EditInspectionObject);

    const ArrOfResults = ArrOfNewInspectionObj.filter((inspections) =>
      inspections[0].includes("status")
    );
    console.log(ArrOfResults, "ArrOfResults");

    const overAllStaus = ArrOfResults.every(
      (inspection) => inspection[1] === "Pass"
    );

    if (overAllStaus) {
      setInspectionStatus(true);
    } else {
      setInspectionStatus(false);
    }

    // filter out Dispenser's all inspection status
    const arrOfComments_D = ArrOfNewInspectionObj.filter((obj) =>
      obj[0].includes("complete_D")
    );
    // check if all inspection is complete
    const isAnyCommentEmpty_D = arrOfComments_D.every(
      (comment) => comment[1] === true
    );
    // if any inspection is incomplete disable Next & Submit button
    if (isAnyCommentEmpty_D) {
      setDisabledButton((disabledButton) => ({ ...disabledButton, d: false }));
    } else {
      setDisabledButton((disabledButton) => ({ ...disabledButton, d: true }));
    }

    // filter out Tank's all inspection status
    const arrOfComments_B = ArrOfNewInspectionObj.filter((obj) =>
      obj[0].includes("complete_B")
    );
    // check if all inspection is complete
    const isAnyCommentEmpty_B = arrOfComments_B.every(
      (comment) => comment[1] === true
    );
    // if any inspection is incomplete disable Next & Submit button
    if (isAnyCommentEmpty_B) {
      setDisabledButton((disabledButton) => ({ ...disabledButton, b: false }));
    } else {
      setDisabledButton((disabledButton) => ({ ...disabledButton, b: true }));
    }

    const arrOfComments_T = ArrOfNewInspectionObj.filter((obj) =>
      obj[0].includes("complete_T")
    );

    // check if all inspection is complete
    const isAnyCommentEmpty_T = arrOfComments_T.every(
      (comment) => comment[1] === true
    );
    // if any inspection is incomplete disable Next & Submit button
    if (isAnyCommentEmpty_T) {
      setDisabledButton((disabledButton) => ({ ...disabledButton, t: false }));
    } else {
      setDisabledButton((disabledButton) => ({ ...disabledButton, t: true }));
    }
  }, [EditInspectionObject]);
  console.log("Inspection Status", inspectionStatus);

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  Routes = employeeRoutes;
  endpoints = employeeEndpoints;

  const [
    {
      data: getInspectionData,
      loading: InspectionDataLoading,
      error: InspectionDataError,
    },
    refreshInspectionData,
  ] = Routes(
    {
      url: endpoints.view_inspection.path,
      method: endpoints.view_inspection.method,
      headers: config.header,
      data: {
        iid: sessionStorage.getItem("inspection_edit_id"),
      },
    },
    { manual: false }
  );

  const [
    {
      data: editInspectionData,
      loading: editInspectionLoading,
      error: editInspectionError,
    },
    editInspection,
  ] = Routes(
    {
      url: endpoints.edit_inspection.path,
      method: endpoints.edit_inspection.method,
      headers: config.header,
      data: { ...EditInspectionObject, inspectionStatus: inspectionStatus },
    },
    { manual: true }
  );

  const [SW, updateState] = useState({
    form: {},
  });

  function onStepChange(step) {
    const formtop = document.getElementById("form-top");
    formtop.scrollIntoView(false, { behavior: "smooth" });
  }

  function checkAllTestSetAndAPI() {
    if (
      EditInspectionObject.status_D1 &&
      EditInspectionObject.status_D2 &&
      EditInspectionObject.status_D3 &&
      EditInspectionObject.status_D4 &&
      EditInspectionObject.status_D5 &&
      EditInspectionObject.status_D6 &&
      EditInspectionObject.status_D7 &&
      EditInspectionObject.status_D8 &&
      EditInspectionObject.status_D9 &&
      EditInspectionObject.status_D10 &&
      EditInspectionObject.status_D11 &&
      EditInspectionObject.status_D12 &&
      EditInspectionObject.status_D13 &&
      EditInspectionObject.status_D14 &&
      EditInspectionObject.status_T1 &&
      EditInspectionObject.status_T2 &&
      EditInspectionObject.status_T3 &&
      EditInspectionObject.status_T4 &&
      EditInspectionObject.status_T5 &&
      EditInspectionObject.status_T6 &&
      EditInspectionObject.status_T7 &&
      EditInspectionObject.status_T8 &&
      EditInspectionObject.status_T9 &&
      EditInspectionObject.status_T10 &&
      EditInspectionObject.status_T11 &&
      EditInspectionObject.status_T12 &&
      EditInspectionObject.status_B1 &&
      EditInspectionObject.status_B2 &&
      EditInspectionObject.status_B3 &&
      EditInspectionObject.status_B4 &&
      EditInspectionObject.status_B5 &&
      EditInspectionObject.status_B6 &&
      EditInspectionObject.status_B7 &&
      EditInspectionObject.status_B8 &&
      EditInspectionObject.status_B9
    ) {
      const convertedData = {
        ...getInspectionData.inspection,
        iid: sessionStorage.getItem("inspection_edit_id"),
        inspectionStatus: inspectionStatus,
        inspections: {
          Tank_inspection: {},
          Dispenser_inspection: {},
          TCEQ_inspection: {},
        },
      };

      for (const key in EditInspectionObject) {
        if (EditInspectionObject.hasOwnProperty(key)) {
          // separate key (label_T1 => [label, T1])
          const [prefix, rest] = key.split("_");
          // check if rest is not undefined
          if (rest) {
            // initialize empty item object
            // with object key (T1, D1, B1....)
            const item = {
              [rest]: {
                status: "",
                comment: "",
                label: "",
              },
            };

            // assign value to item object
            item[rest].comment = EditInspectionObject[`comment_${rest}`];
            item[rest].label = EditInspectionObject[`label_${rest}`];
            item[rest].status = EditInspectionObject[`status_${rest}`];

            if (rest.charAt(0) === "T") {
              convertedData.inspections.Tank_inspection[rest] = item[rest];
            } else if (rest.charAt(0) === "D") {
              convertedData.inspections.Dispenser_inspection[rest] = item[rest];
            } else if (rest.charAt(0) === "B") {
              convertedData.inspections.TCEQ_inspection[rest] = item[rest];
            }
          } else {
            convertedData[key] = EditInspectionObject[key];
          }
        }
      }

      editInspection({ data: convertedData }).then((res) => {
        if (res.status === 200) {
          window.location.href = "./inspection-list";
        }
      });
    } else {
      alert("Please fill all the fields");
    }
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Edit Inspection" parentName="Inspection" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Edit Inspection</h3>
          </CardHeader>
          <CardBody>
            <Form>
              <StepWizard instance={updateState} onStepChange={onStepChange}>
                <div>
                  <Row
                    className={"align-content-center p-3 d-flex"}
                    id={"form-top"}
                  >
                    <Col xs="6" className={"justify-content-start d-flex"}>
                      <Button
                        type={"button"}
                        color="danger"
                        onClick={SW.previousStep}
                      >
                        Go Back
                      </Button>
                    </Col>

                    <Col xs="6" className={"justify-content-end d-flex"}>
                      <Button
                        type={"button"}
                        color="success"
                        onClick={SW.nextStep}
                        disabled={disabledButton.d}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Dispenser Check List
                  </h6>
                  <h4>
                    <ListGroup>
                      {getInspectionData &&
                        Object.entries(
                          getInspectionData.inspection.inspections
                            .Dispenser_inspection
                        ).map((checklist) => (
                          <ListGroupItem key={checklist[0]}>
                            <InspectionEditRow
                              id={checklist[0]}
                              detail={checklist[1]}
                              onChange={handleNewInsp}
                              inverse={false}
                              label={checklist[1].label}
                              status={checklist[1].status}
                              comment={checklist[1].comment}
                            />
                          </ListGroupItem>
                        ))}
                    </ListGroup>

                    <Row className={"align-content-center p-3 d-flex"}>
                      <Col xs="6" className={"justify-content-start d-flex"}>
                        <Button
                          type={"button"}
                          color="danger"
                          onClick={SW.previousStep}
                        >
                          Go Back
                        </Button>
                      </Col>

                      <Col xs="6" className={"justify-content-end d-flex"}>
                        <Button
                          type={"button"}
                          color="success"
                          onClick={(e) => {
                            SW.nextStep();
                          }}
                          disabled={disabledButton.d}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </h4>
                </div>
                <div>
                  <Row
                    className={"align-content-center p-3 d-flex"}
                    id={"form-top"}
                  >
                    <Col xs="6" className={"justify-content-start d-flex"}>
                      <Button
                        type={"button"}
                        color="danger"
                        onClick={SW.previousStep}
                      >
                        Go Back
                      </Button>
                    </Col>

                    <Col xs="6" className={"justify-content-end d-flex"}>
                      <Button
                        type={"button"}
                        color="success"
                        onClick={SW.nextStep}
                        disabled={disabledButton.t}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Tank Check List
                  </h6>
                  <h4>
                    <ListGroup>
                      {getInspectionData &&
                        Object.entries(
                          getInspectionData.inspection.inspections
                            .Tank_inspection
                        ).map((checklist) => (
                          <ListGroupItem key={checklist[0]}>
                            <InspectionEditRow
                              id={checklist[0]}
                              detail={checklist[1]}
                              onChange={handleNewInsp}
                              inverse={false}
                              label={checklist[1].label}
                              status={checklist[1].status}
                              comment={checklist[1].comment}
                            />
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                    <Row className={"align-content-center p-3 d-flex"}>
                      <Col xs="6" className={"justify-content-start d-flex"}>
                        <Button
                          type={"button"}
                          color="danger"
                          onClick={SW.previousStep}
                        >
                          Go Back
                        </Button>
                      </Col>

                      <Col xs="6" className={"justify-content-end d-flex"}>
                        <Button
                          type={"button"}
                          color="success"
                          onClick={SW.nextStep}
                          disabled={disabledButton.t}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </h4>
                </div>
                <div>
                  <Row
                    className={"align-content-center p-3 d-flex"}
                    id={"form-top"}
                  >
                    <Col xs="6" className={"justify-content-start d-flex"}>
                      <Button
                        type={"button"}
                        color="danger"
                        onClick={SW.previousStep}
                      >
                        Go Back
                      </Button>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    TCEQ Book Check List
                  </h6>
                  <h4>
                    <ListGroup>
                      {getInspectionData &&
                        Object.entries(
                          getInspectionData.inspection.inspections
                            .TCEQ_inspection
                        ).map((checklist) => (
                          <ListGroupItem key={checklist[0]}>
                            <InspectionEditRow
                              id={checklist[0]}
                              detail={checklist[1]}
                              onChange={handleNewInsp}
                              inverse={false}
                              label={checklist[1].label}
                              status={checklist[1].status}
                              comment={checklist[1].comment}
                            />
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                    <Row className={"align-content-center p-3 d-flex"}>
                      <Col xs="6" className={"justify-content-start d-flex"}>
                        <Button
                          type={"button"}
                          color="danger"
                          onClick={SW.previousStep}
                        >
                          Go Back
                        </Button>
                      </Col>

                      <Col xs="6" className={"justify-content-end d-flex"}>
                        <Button
                          type={"button"}
                          color="success"
                          onClick={checkAllTestSetAndAPI}
                          disabled={disabledButton.b}
                        >
                          Finish
                        </Button>
                      </Col>
                    </Row>
                  </h4>
                </div>
              </StepWizard>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default InspectionEdit;
