// importing Pages
import Alternative from "../views/pages/dashboards/Alternative.js";
import Dashboard from "../views/pages/dashboards/Dashboard.js";
import Login from "../views/pages/login/Login.js";

//importing manager routes access level 1
import manager_routes from "./manager.routes";

// importing employee routes access level 2
import employee_routes from "./employee.routes";
import StationFM from "../views/pages/station/StationFM";
import Test from "../views/pages/station/test";

const routes_base = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    invisible: true,
    component: Login,
    layout: "/auth",
  },
  /*{
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    access_level: "1",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "D",
        component: Dashboard,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        miniName: "A",
        access_level: "1",
        component: Alternative,
        layout: "/admin",
        invisible: false,
      },
      // {
      //   path: "/files",
      //   name: "files",
      //   miniName: "A",
      //   access_level: "1",
      //   component: StationFiles,
      //   layout: "/admin",
      //   invisible: false,
      // },
      // {
      //   path: "/file",
      //   name: "file manager",
      //   miniName: "A",
      //   access_level: "1",
      //   component: Test,
      //   layout: "/admin",
      //   invisible: false,
      // },
    ],
  },*/
];
const routes = routes_base.concat(manager_routes).concat(employee_routes);
export default routes;