import React from "react";
import InspectionDropzone from "../../../components/Inspection/InspectionDropzone";
import { Container } from "reactstrap";

function Test(props) {
  return (
    <Container fluid>
      <InspectionDropzone insp_id={"63a04332139756eb8ed0d9a1"} />
    </Container>
  );
}

export default Test;