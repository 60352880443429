import { S3 } from "@aws-sdk/client-s3";

const old_BUCKET_NAME = "pg-test";
const old_endpoint = "https://nyc3.digitaloceanspaces.com/";
const old_BUCKET_REGION = "us-east-1";
const old_ACCESS_KEY_ID = "DO00FW6E2D7EN2HAVTCU";
const old_SECRET_ACCESS_KEY = "kBmUkBcicwBUmLc9WsIQ5Py+//C4noII981en4uctb0";

const BUCKET_NAME = "toc-i";
const endpoint = "https://nyc3.digitaloceanspaces.com/";
const BUCKET_REGION = "us-east-1";
const ACCESS_KEY_ID = "DO00RCDMUXEBATVHN86X";
const SECRET_ACCESS_KEY = "iNxrX1QWxmOwxOhEj7uJO0scc1W9aRxSKuMJdW2JbGA";

const s3Client = new S3({
  // forcePathStyle: false, // Configures to use subdomain/virtual calling format.
  endpoint: "https://nyc3.digitaloceanspaces.com",
  region: "us-east-1",
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

export { s3Client, BUCKET_NAME };