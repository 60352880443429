import React, { useEffect, useState } from "react";
import { Badge, Col, Input, Label } from "reactstrap";

function InspectionRow(props) {
  const id = props.id;
  const no = "no" + id;
  const yes = "yes" + id;
  const name = "status_" + id;
  const comment = "comment_" + id;
  const complete_status = "complete_" + id;
  const inverse = props.inverse;
  const label = props.label;
  const [isFailReq, setIsFailReq] = useState(false);
  const [iscomplete, setIscomplete] = useState(false);
  const [inspec_status, setInspec_status] = useState("");
  const [inspec_comment, setInspec_comment] = useState("");

  function handleChange() {
    let compl = false;
    if (inspec_status === "Pass") {
      setIscomplete(true);
      compl = true;
    } else if (inspec_status === "Fail") {
      if (inspec_comment.trim().length === 0) {
        console.log("settings false");
        setIscomplete(false);
        compl = false;
        setIsFailReq(true);
      } else {
        console.log("fail settings true");
        setIscomplete(true);
        compl = true;
        setIsFailReq(false);
      }
    }

    props.onChange("label_" + id, label);
    props.onChange(name, inspec_status);
    props.onChange(comment, inspec_comment);
    props.onChange(complete_status, compl);
  }
  useEffect(() => {
    handleChange();
  }, [inspec_comment, inspec_status]);

  return (
    <div className={"row mx-1"}>
      <Col xs={12} md={6} className="align-items-center d-grid">
        <Label htmlFor={id}>
          {label}{" "}
          <Badge
            style={{ fontSize: "0.8rem" }}
            color={inspec_status === "Pass" ? "success" : "danger"}
          >
            {inspec_status}
          </Badge>
          {/* this display individual inspection status complete/incomplete statsus */}
          {/* <Badge
            style={{ fontSize: "0.8rem" }}
            color={iscomplete ? "success" : "danger"}
          >
            {iscomplete ? "Completed" : "Incomplete"}
          </Badge> */}
        </Label>
        <div id={id}>
          <div className="custom-control custom-radio justify-content-end custom-control-inline">
            <input
              className="custom-control-input"
              id={no}
              name={name}
              type="radio"
              value={inverse ? "Pass" : "Fail"}
              onChange={(e) => {
                setIsFailReq(inverse ? false : true);
                setInspec_status(e.target.value);
              }}
            />
            <label className="custom-control-label" htmlFor={no}>
              No
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              className="custom-control-input"
              id={yes}
              name={name}
              type="radio"
              value={inverse ? "Fail" : "Pass"}
              onChange={(e) => {
                setIsFailReq(inverse ? true : false);
                setInspec_status(e.target.value);
              }}
            />
            <label className="custom-control-label" htmlFor={yes}>
              Yes
            </label>
          </div>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <Label>
          Comments:{" "}
          {isFailReq && <span className="fw-bold text-danger">*</span>}
        </Label>
        <Input
          className={isFailReq ? "is-invalid" : ""}
          type={"textarea"}
          id={comment}
          name={comment}
          onChange={(e) => {
            setInspec_comment(e.target.value);
          }}
          required={isFailReq}
        />
        {isFailReq && <span className="text-danger">Comment Required</span>}
      </Col>
    </div>
  );
}

export default InspectionRow;
