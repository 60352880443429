import WorkOrderList from "../views/pages/workOrder/WorkOrderList";
import WorkOrderNew from "../views/pages/workOrder/WorkOrderCreate";
import EmployeeList from "../views/pages/employee/EmployeeList";
import StationList from "../views/pages/station/StationList";
import WorkOrderView from "../views/pages/workOrder/WorkOrderView";
import InspectionList from "../views/pages/inspection/InspectionList";
import InspectionView from "../views/pages/inspection/InspectionView";
import StationFm from "../views/pages/station/StationFM";
import StationDetail from "../views/pages/station/StationDetail";
import StationFileExpiryList from "../views/pages/station/StationFileExpiryList";
import PendingWorkOrderList from "views/pages/workOrder/PendingWorkOrderList";

const manager_routes = [
  {
    collapse: true,
    name: "Station",
    icon: "ni ni-shop text-primary",
    state: "stationCollapse",
    access_level: "1",
    views: [
      {
        path: "/manager/station-list",
        name: "Station List",
        miniName: "SL",
        component: StationList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/manager/station-files",
        name: "Station File Manager",
        miniName: "SL",
        component: StationFm,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/manager/station-file-expiry",
        name: "Station Files Near Expiry",
        miniName: "SL",
        component: StationFileExpiryList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/manager/station-details",
        name: "Station Details",
        miniName: "SL",
        component: StationDetail,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Engineer",
    icon: "ni ni-single-02  text-primary",
    state: "empCollapse",
    access_level: "1",
    views: [
      {
        path: "/manager/Engineer-list",
        name: "Engineer List",
        miniName: "EL",
        component: EmployeeList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
    ],
  },
  {
    collapse: true,
    name: "Work Order",
    icon: "ni ni-single-copy-04  text-primary",
    state: "WOCollapse",
    access_level: "1",
    views: [
      {
        path: "/manager/work-order-list",
        name: "Work Order List",
        miniName: "WOL",
        component: WorkOrderList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/manager/pending-work-order-list",
        name: "Pending Work Order List",
        miniName: "PWOL",
        component: PendingWorkOrderList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/manager/create-work-order",
        name: "Create Work Order",
        miniName: "CWO",
        component: WorkOrderNew,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/manager/work-order-detail",
        name: "Work Order Details",
        miniName: "WOL",
        component: WorkOrderView,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
    ],
  },

  {
    collapse: true,
    name: "Inspection",
    icon: "ni ni-bullet-list-67  text-primary",
    state: "InspectionCollapse",
    access_level: "1",
    views: [
      {
        path: "/manager/inspection-list",
        name: "Inspection List",
        miniName: "IL",
        component: InspectionList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/manager/inspection-detail",
        name: "Inspection Details",
        miniName: "IL",
        component: InspectionView,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
    ],
  },
];
export default manager_routes;
